.chatbot {
  background: rgba(192, 192, 197, 0.444);
  padding: 20px 0;
}

.chatbot-button {
  background-color: #7e7979;
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 25px;
  cursor: pointer;
  border-radius: 62px;
  transition: background-color 0.3s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 340px;
}

.chatbot-button:hover {
  background-color: #686565;
}

.chatbot-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.chatbot-button-content {
  margin: 50px 0;
  width: 100%;
}

.chatbot-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 300px;
  height: 400px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
}

.chatbot_row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.chatbot-title-container {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.chatbot-title {
  font-family: 'Integral';
  font-size: 35px;
  width: 100%;
}
