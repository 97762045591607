.activity-container {
    padding: 20px;
    background: rgba(192, 192, 197, 0.444);
}

.activity-content {
    display: flex;
    gap: 50px;
    justify-content: center;
    align-items: center;
}

.activity-title {
    font-family: 'Integral';
    font-size: 25px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
}

.activity-text {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
}

.activity-item {
    font-size: 18px;
    list-style: square;
    line-height: 50px;
    font-style: italic;
}
