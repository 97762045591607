.about-us {
    background: rgba(132, 132, 135, 0.444);
    padding: 20px;
}

.about-container {
    display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.about-content {
    max-width: 900px;
    width: 100%;
    padding: 20px;
}

.about-title {
    font-family: 'Integral';
    font-size: 25px;
    font-weight: 400;
    text-transform: uppercase;
}

.about-text {
    margin: 30px 0;
    font-size: 18px;
    font-weight: 100;
    line-height: 30px;
    overflow: hidden;
    white-space: pre-wrap;
    text-align: justify;
    font-style: italic;
}
