.header {
  background-color: #f8f9fa;
  padding: 20px 0;
}

.header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-logo img {
  max-width: 400px;
  height: auto;
  display: block;
}

.header-nav--main ul {
  display: flex;
  gap: 20px;
}

.header-nav--main ul li {
  font-size: 18px;
  font-weight: 700;
}

.header-nav--main ul li a {
  text-decoration: none;
  color: #060606;
  transition: color 0.3s;
}

.header-nav--main ul li a:hover {
  color: #6d7175;
}

@media (max-width: 768px) {
  .header-logo img {
    max-width: 200px;
  }

  .header-nav--main ul li {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .header-logo img {
    max-width: 100px;
  }

  .header-nav--main ul {
    gap: 10px;
  }

  .header-nav--main ul li {
    font-size: 12px;
  }
}
