.footer {
    background-color: #7e7979;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 40px;
}

.footer-contacts h1 {
    font-family: 'Integral';
    font-size: 25px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 20px 0;
}

.footer-contacts p {
    font-size: 20px;
    font-weight: 800;
}

.footer-logo img {
    width: 400px;
    height: 150px;
}

.footer-text {
    padding-top: 20px;
}

.footer-icons h1 {
    font-family: 'Integral';
    font-size: 20px;
    font-weight: 800;
    text-transform: uppercase;
    margin: 20px 0;
}

.social-icon {
    margin-right: 20px;
}
  
.social-icon:last-child {
    margin-right: 0;
}

.social-icon img {
    transition: filter 0.3s;
}
  
.social-icon:hover img {
    filter: brightness(0) invert(1);
}

@media (max-width: 768px) {
  .footer-contacts h1, .footer-icons h1 {
    font-size: 18px;
  }

  .footer-contacts p {
    font-size: 16px;
  }

  .footer-logo img {
    width: 300px;
    height: auto;
  }

  .footer-text {
    padding-top: 15px;
  }

  .social-icon {
    margin-right: 15px;
  }
}

@media (max-width: 576px) {
  .footer-contacts h1, .footer-icons h1 {
    font-size: 16px;
  }

  .footer-contacts p {
    font-size: 14px;
  }

  .footer-logo img {
    width: 200px;
  }

  .footer-text {
    padding-top: 10px;
  }

  .social-icon {
    margin-right: 10px;
  }
}
